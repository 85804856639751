export const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_RemoteServer : process.env.REACT_APP_LocalServer;
export const clientUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_RemoteClient : process.env.REACT_APP_LocalClient;
export const brandingClientUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_RemoteClientBranding : process.env.REACT_APP_LocalClientBranding;
export const resClientUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_RemoteClientRes : process.env.REACT_APP_LocalClientRes;
export const agentsClientUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_RemoteClientAgents : process.env.REACT_APP_LocalClientAgents;

export const setHeader = () => {
    const headers = {
        headers: { 'Content-Type': 'application/json' }
    }
    return headers
}

export const setAuthHeader = () => {
    const headers = {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')) }
    }
    return headers
}