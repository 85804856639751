import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../Redux/Slices/AuthSlice';
import './Nav.css'
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { url } from '../Redux/Api';

function NavBar() {
    const user = useSelector(state=>state.auth.user);
    const dispatch = useDispatch()

    const handleLogout = ()=>{
      async function fetchData(){
        await fetch(`${url}/logout`,{
            mode:'cors',
            method:'put',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({
              token:JSON.parse(localStorage.getItem('token'))
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                dispatch(logOut())
            }
        })
    }
    fetchData();
    }
    
    return (<Navbar expand="lg" className="bg-body-tertiary fixed-top">
      <Container fluid>
        <Navbar.Brand href='/'>
        <h4 className='logo'>RETINY</h4>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
          </Nav>
            <Nav style={{marginRight:'50px'}}>
        
        {user?.isAdmin&&
            <Nav.Link className="navbar-link" href={`/${user?._id}/dashboard`}>Dashboard</Nav.Link>}
            
            {user?
            <Nav.Link className="navbar-link" onClick={handleLogout}>Logout</Nav.Link>
            :<><Nav.Link className="navbar-link" href={`/login`}>Login</Nav.Link>
        <Nav.Link className="navbar-link" href={`/register`}>Register</Nav.Link></>}
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>)
}
export default NavBar