import Select from 'react-select'
import { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import countryList from 'react-select-country-list'
import { clientUrl, url } from '../../../Redux/Api';
import { loginUser } from '../../../Redux/Slices/AuthSlice';
import { useDispatch } from 'react-redux';
import { Button, Container, Spinner } from 'react-bootstrap';
import './Auth.css';

function Confirmation() {
  const [userName, setUserName] = useState("");
  const [userNamed, setUserNamed] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [phone, setPhone] = useState('');
  const [usernameTakenText, setUsernameTakenText] = useState("");
  const [usernameAvailableText, setUsernameAvailableText] = useState("");
  const [existingUserNameState, setExistingUserNameState] = useState(false);
  const [userNameData, setUserNameData] = useState('');

  const[agree, setAgree] = useState(false)
  const[disagree, setDisagree] = useState(false)

  const navigate = useNavigate()
  const[complete, setComplete] = useState(false);
  const[country, setCountry] = useState({});
  const{email,referrer,token} = useParams()
  const[password, setPassword] = useState("");
  const[newState, setNewState] = useState({status:'',data:''});
  const options = useMemo(() => countryList().getData(), [])
  const[confirmedPassword, setConfirmedPassword] = useState("");
  const[passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const[loading, setLoading] = useState(false);
  const[loadingReg, setLoadingReg] = useState(false);

  useEffect(()=>{
if(lastName.length>1&&firstName.length>1&&userName.length>3&&country!={}&&email.length>4&&password.length>7&&confirmedPassword.length>7&&phone.length>6){
  return setComplete(true)
}else{
  setComplete(false)
}
  },[email,password,country,firstName,userName,confirmedPassword,phone,lastName])
    const handleAgree = ()=>{
    setDisagree(false)
    setAgree(true)
}
const handleDisagree = ()=>{
    setDisagree(true)
    setAgree(false)
}

useEffect(()=>{
  if(password !=confirmedPassword){
    setPasswordMismatch(true)
    setNewState({status:'falied',data:"Passwords don't match"})
    return setLoadingReg(false)
  }else{
    setPasswordMismatch(false)
    setNewState({status:'success',data:""})
    return setLoadingReg(false)
  }
},[confirmedPassword,password])
//Cotinue from here
// const usernameSearchHandler = async(e) =>{
//   await fetch('/users',{
//     method:'get'
//   }).then(res=>res.json())
//   .then(data=>{
//     if(data){
//       const condition = data?.map(item=>item.userName).includes(userName)
//       setUserNameData(condition)
//       console.log('Check it now',condition,'usernameTakenText')
//     }
//   }).catch(err=>console.log(err))
// }
const usernameSearchHandler = async(e) =>{
  e.preventDefault()
  if(userName.length>3){
    setUsernameTakenText('')
  await fetch(`${url}/check-username`,{
    method:'post',
    headers:{
      'Content-Type':'application/json'
    },
    body:JSON.stringify({userName,firstName,lastName})
  }).then(res=>res.json())
  .then(resp=>{
    if(resp.resp){
      setLoading(false)
      return setNewState({status:'success',data:resp.resp})
    }else if(resp.err){
      setLoading(false)
      return setNewState({status:'failed',data:resp.err})
    }
  }).catch(err=>{console.log(err);setLoading(false);return setNewState({status:'failed',data:err.message})})
  }
}

useEffect(()=>{
  if(userName.length>3){
    if(userName.length>3){
      setUsernameTakenText('')
     fetch(`${url}/check-username`,{
      method:'post',
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify({userName,firstName,lastName})
    }).then(res=>res.json())
    .then(resp=>{
      if(resp.resp){
        setLoading(false)
        return setNewState({status:'success',data:resp.resp})
      }else if(resp.err){
        setLoading(false)
        return setNewState({status:'failed',data:resp.err})
      }
    }).catch(err=>{console.log(err);setLoading(false);return setNewState({status:'failed',data:err.message})})
    }
  }
},[userName])
// useEffect(()=>{
//   async function fetchData(){
//     await fetch(`${url}/users`,{
//       method:'get'
//     }).then(res=>res.json())
//     .then(data=>{
//       if(data){
//         if(data?.length>0){
//           const condition = data?.map(item=>item?.userName).includes(userName)
//         setUserNameData(condition)
//         setExistingUserNameState(condition)
//         }
//       }else{
//         return
//       }
//     }).catch(err=>console.log(err))
//   }
//   fetchData()

// },[userName]);
// useEffect(()=>{
//   if(userNameData && userName.length>0){
//     setUsernameTakenText(userNameData.msg)
//   }
// },[userNameData,userName])
const changeHandler = value => {
  setCountry(value)
}
  const handleRegistration = async(a) => {
    a.preventDefault();
    setLoadingReg(true)
      await fetch(`${url}/register`,{
        method:'post',
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify({userName,
          email,
          firstName,
          lastName,
          middleName,
          country,
          phone,
          agent:clientUrl,
          password,
          confirmedPassword})
      }).then(res=>res.json()).then(resp=>{
        if(resp.resp){
          setLoadingReg(false)
          setNewState({status:'Success',data:resp.resp});
          navigate('/login')
        }else if(resp.err){
          setLoadingReg(false)
          setNewState({status:'failed',data:resp.err});
      }
    }).catch(e=>{setNewState({status:'failed',data:e.message});setLoadingReg(false)})
  };
  
  return (<Container className='auth-container'>
      <h5 className={newState.data.length>0&&newState.status==='success'?'success':'error'}>
              {
                userName.length>3&&<div style={{color:'var(--danger-1)',textAlign:'left'}}>{userName}</div>
              }
                {newState.data.length>0?newState.data:<div style={{color:'var(--danger-1)',textAlign:'center'}}>Register</div>}
                </h5> 
        <form
        onSubmit={handleRegistration}
          className='form'
        >
          <div >
          <div className='inputs-wrapper'>
          {/* <input className={
                userName.length>0&&usernameTakenText!==''?
                  'unavailable-name'
                 :userName.length>0&&usernameTakenText===''?'available-name':'login-input'
              
            }
              placeholder={
                userName.length>0&&usernameTakenText!==''&&usernameAvailableText===''?
                usernameTakenText
                 :userName.length>0&&usernameTakenText===''&&usernameAvailableText!==''?usernameAvailableText:'Enter unique user name'
                }
              type='text'
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              onMouseLeave={usernameSearchHandler}
              required
            /> */}

<div className="mb-3 row">
    <label htmlFor="userName" className="col-sm-2 col-form-label">User Name</label>
    <div className="col-sm-10">
      <input  id="userName" 
              className={
                userName.length>0&&usernameTakenText!==''?
                  'unavailable-name'
                 :userName.length>0&&usernameTakenText===''?'available-name':'form-control'
              
            }
              placeholder={
                userName.length>0&&usernameTakenText!==''&&usernameAvailableText===''?
                usernameTakenText
                 :userName.length>0&&usernameTakenText===''&&usernameAvailableText!==''?usernameAvailableText:'Enter unique user name'
                }
              type='text'
              value={userName.toLowerCase()}
              onChange={(e) => setUserName(e.target.value)}
              onMouseLeave={usernameSearchHandler}
              required
      />
    </div>
  </div>
            
            <div className="mb-3 row">
    <label htmlFor="middleName" className="col-sm-2 col-form-label">First Name</label>
    <div className="col-sm-10">
      <input className="form-control"  id="firstName"
              required={false}
              type='text'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
      />
    </div>
  </div>
          </div>
           <div className='inputs-wrapper'>
           <div className="mb-3 row">
    <label htmlFor="lastName" className="col-sm-2 col-form-label">Last Name</label>
    <div className="col-sm-10">
      <input className="form-control"  id="lastName" 
              required
              type='text'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
      />
    </div>
  </div>

<div className="mb-3 row">
    <label htmlFor="middleName" className="col-sm-2 col-form-label">Middle Name(Optional)</label>
    <div className="col-sm-10">
      <input className="form-control"  id="middleName" 
              required={false}
              type='text'
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
      />
    </div>
  </div>
            
           </div>
           
           <div className='inputs-wrapper'>
<div className="mb-3 row">
    <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
    <div className="col-sm-10">
      <input className="form-control"  id="email" 
              type='email'
              value={email}
              disabled
      />
    </div>
  </div>
<div className="mb-3 row">
    <label htmlFor="phone" className="col-sm-2 col-form-label">Phone(Include country code)</label>
    <div className="col-sm-10">
      <input className="form-control"  id="phone" 
              onChange={(e) => setPhone(e.target.value)}
      type="tel" name={phone}
      required
      />
    </div>
  </div>
            </div>
            <div className="mb-3 row">
    <label htmlFor="country" className="col-sm-2 col-form-label">Country</label>
    <div className="col-sm-10">
    <Select options={options} value={country} id='country' onChange={changeHandler}/>
    </div>
  </div>
           <div className='inputs-wrapper'>

<div className="mb-3 row">
    <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
    <div className="col-sm-10">
      <input className="form-control" onChange={(e) => setPassword(e.target.value)} id="password" 
      type={passwordType}
      required
      />
    </div>
  </div>
           <div className="mb-3 row">
    <label htmlFor="confirmedPassword" className="col-sm-2 col-form-label">Confirm Password</label>
    <div className="col-sm-10">
      <input className="form-control" onChange={(e) => setConfirmedPassword(e.target.value)} id="confirmedPassword" 
      type={passwordType}
      required
      placeholder={password !==confirmedPassword? "Passwords Don't Match":`${confirmedPassword===''?'Confirm Password':''}`}
       />
    </div>
  </div>
           </div>
            </div>
            <a  href ='https://terms.retiny.org' target='_blank' rel='noreferrer' >Terms and conditions</a>
            {
              loading? <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>:
              <>
              {
                userName.length>3&&newState.status!=='failed'&&
                <div style={{display:'flex',gap:'20px'}}>
                {
                     agree===false&&<Button onClick={handleAgree} className='btn btn-primary'>Agree</Button>
                 }
                 {
                     disagree===false&&<Button onClick={handleDisagree}className='contact-btn'>Disagree</Button>
                 }
               
                 {
                     loadingReg===true?<Spinner />:
                     <>{
                         complete&&agree===true?
                         <Button type='submit'>Create</Button>:!complete&&agree===true&&<p>Fill out all required field.</p>
                     }
                     </>
                 }
                  </div>
              }
              </>
            }
        </form>
    </Container>
  );
}
export default Confirmation