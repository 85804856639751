import { configureStore } from '@reduxjs/toolkit';
import userReducer, { loadUser } from './Slices/AuthSlice';
import usersReducer, { getUsers } from './Slices/usersSlice';
import visitorsReducer, { getVisitors } from './Slices/visitorsSlice';
import visitorReducer, { getVisitor } from './Slices/visitorSlice';
import { usersApi } from './UsersApi';
import contactsReducer, { getContacts } from './Slices/contactsSlice';
import appReducer, { getApps } from './Slices/appSlice';

export const store = configureStore({
  reducer: {
    auth:userReducer,
    users:usersReducer,
    apps:appReducer,
    visitor:visitorReducer,
    visitors:visitorsReducer,
    contacts:contactsReducer,
    [usersApi.reducerPath]:usersApi.reducer
  },
  middleware:(getDefaultMiddleware)=>(
    getDefaultMiddleware().concat(usersApi.middleware)
  ),
  devTools: process.env.NODE_ENV !== 'production',
})

store.dispatch(getVisitor())
store.dispatch(getVisitors())
store.dispatch(getContacts())
store.dispatch(getUsers())
store.dispatch(getApps())

//Loaders
store.dispatch(loadUser())