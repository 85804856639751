import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../Redux/Api';
import './Register.css'
import { Button, Container, Form, Spinner } from 'react-bootstrap';

const Register = props => {
const[email, setEmail] = useState('');
const[loading, setLoading] = useState(false);
const[lastMailTried, setLastMailTried] = useState('');
const[newState, setNewState] = useState({status:'',data:''});
const[captured, setCaptured] = useState('')
const navigate = useNavigate();

const handlePreRegistration = async(e)=>{
    e.preventDefault()
    setLoading(true)
            await fetch(`${url}/check-email`,{
        mode:'cors',
        method:'post',
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify({
            email
        })
    }).then(res=>res.json())
    .then(data=>{
        if(data.resp){
          fetch(`${url}/create-account`,{
            mode:'cors',
            method:'post',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                email
            })
        }).then(res=>res.json())
        .then(data=>{
            if(data.resp){
                return navigate(`/${email}/mail-delivery`)
            }else if(data.err){
              setLastMailTried(data.err)
              setLoading(false)
              setCaptured(data.err.toString())
            }else{
            setLoading(false)
            return setNewState({status:'failed',data:'Something went wrong, please try again.'});
          }
        }).catch(err=>{
            setNewState({status:'failed',data:err.message})
            setLoading(false)
        })
        }else if(data.err){
          setLoading(false)
          return setNewState({status:'failed',data:data.err});
        }else{
        setLoading(false)
        return setNewState({status:'failed',data:'Something went wrong, please try again.'});
      }
    }).catch(err=>{
        setNewState({status:'failed',data:err.message})
        setLoading(false)
    })
}

return <Container className='auth-container'>
        
      <Form onSubmit={handlePreRegistration}>
      {
            newState?.data?.length>0?
            <p className={newState?.status==='success'?'success-message':'error-message'} style={{textAlign:'center'}}>{newState?.data}</p>:lastMailTried.length>0&&lastMailTried.toString()===email.toString()?<p className={newState?.status==='success'?'success-message':'error-message'} style={{textAlign:'center'}}>{lastMailTried}</p>:<p style={{textAlign:'center'}}>Registration</p>
        }
      <div className='inputs-wrapper'>
        <div className="mb-3 row">
    <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
    <div className="col-sm-10">
      <input type="text" className="form-control" onChange={e=>setEmail(e.target.value)} id="email" value={email} />
    </div>
  </div>
        </div>
  
  {loading?<Spinner/>:<Button type='submit' className='contact-btn'>Continue</Button>}
      </Form>
    </Container>;
};

Register.propTypes = {};

export { Register };