import React, {useState} from 'react';
import { Button, Col, Container, Form, FormControl, Row, 
        Spinner,ListGroup, ListGroupItem, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { url } from '../../../Redux/Api';
import './Dashboard.css'

const Dashboard = props => {
    const user = useSelector(state=>state.auth.user);
    const users = useSelector(state=>state.users.items);
    const contacts = useSelector(state=>state.contacts.items);
    const subscriptions = useSelector(state=>state.subscriptions.items);
    const visits = useSelector(state=>state.visitors.items);

    
    const[active, setActive] = useState('plan');
    const[title, setTitle] = useState('');
    const[partTimeDuration, setPartTimeDuration] = useState(36);
    const[regularDuration, setRegularDuration] = useState(12);
    const[partTimeMeetingDays, setPartTimeMeetingDays] = useState('Saturdays');
    const[partTimeMeetingTime, setPartTimeMeetingTime] = useState('3pm-6pm')
    const[regularMeetingDays, setRegularMeetingDays] = useState('Mondays, Wednesdays, Fridays');
    const[regularMeetingTime, setRegularMeetingTime] = useState('4pm-6pm');
    const[description, setDescription] = useState('');
    const[cost, setCost] = useState(0);
    const[venue, setVenue] = useState('Retiny Event Space');
    const[owner, setOwner] = useState('');
    const[newCode, setNewCode] = useState('');
    const[newCodetitle, setNewCodeTitle] = useState('');
    const[codes, setCodes] = useState([]);
    const[newState, setNewState] = useState({status:'',data:''});
    const[loading, setLoading] = useState(false);
    const[imagePreview, setImagePreview] = useState('');
    const[planCategory,setPlanCategory] = useState('');
    const[planDescription,setPlanDescription] = useState('');
    const[planCost,setPlanCost] = useState(0);

    const[email,setEmail] = useState('');
    const[domain,setDomain] = useState('');
    const[business,setBusiness] = useState('');

    function handleAddCode(){
      setCodes([...codes,{title:newCodetitle,code:newCode}]);
      setNewCode('');
      setNewCodeTitle('')
    }
    function handleRenewal(){
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/plan-renewal-notice`,{
                  mode:'cors',
                  method:'put',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
                    email,business,domain
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }
    function handleCreatePlan(e){
      e.preventDefault()
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/create-plan`,{
                  mode:'cors',
                  method:'post',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
                    planCategory,planCost,description:planDescription
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }
    function handleUnSubscriptionConfirmation(e){
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/unconfirm-subscription`,{
                  mode:'cors',
                  method:'put',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
                    subscriptionId:e._id
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }
    function handleSubscriptionConfirmation(e){
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/confirm-subscription`,{
                  mode:'cors',
                  method:'put',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
                    subscriptionId:e._id
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }
    function handlePaymentConfirmation(e){
        setLoading(true)
     
        async function fetchData(){
                await fetch(`${url}/confirm-payment`,{
                    mode:'cors',
                    method:'put',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    },body:JSON.stringify({
                        enrolmentId:e._id
                    })
                }).then(res=>res.json())
                .then(resp=>{
                    if(resp.resp){
                        setLoading(false)
                        setNewState({status:'success',data:resp.resp})
                        return window.location.reload()
                    }else if(resp.err){
                        setLoading(false)
                        return setNewState({status:'failed',data:resp.err})
                    }else{
                        setLoading(false)
                        return setNewState({status:'failed',data:'Something went wrong.'})
                    }
                }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
            }
            fetchData();
   
      
    }

    const handleFile = async (a)=>{
      const data = a.target.files[0];
      const base64 = await Converter(data)
      setImagePreview(base64)
      console.log('file',data);
    }
    const Converter = (e)=>{
      return new Promise((resolve,reject)=>{
        const reader = new FileReader()
        reader.readAsDataURL(e)
        reader.onload = ()=>{
          resolve(reader.result)
        }
        reader.onerror = (error)=>{
          reject(error)
        }
      })
    }
    
    return <Container className='dashboard-container'>
        <h3>{newState.data}</h3>
      {/* <Button onClick={handleSubscriptionConfirmation}>Confirm Subscription</Button>
      <Button onClick={handleUnSubscriptionConfirmation}>Remove Subscription</Button> */}
        <Tabs
      defaultActiveKey={active==='blogs'?'blogs':active==='blog'?'blog':'blogs'}
      id="uncontrolled-tab-example"
      className="mb-3"
    ><Tab eventKey="space" title="Space Form" onClick={()=>setActive('space')}>
    <div style={{margin:'40px auto'}}>
            <h3>Create Plan</h3>
    </div>
    {newState.data.length>0&&<p>{newState.data}</p>}
    <Form onSubmit={handleCreatePlan} autoComplete='true'>
    <Col>
<Row xl={6} md={4} className='code-block'>
<span style={{color:'gray',marginTop:'20px'}}>Plan Cost</span><FormControl 
    onChange={e=>setPlanCost(e.target.value)}
    />
    <span style={{color:'gray',marginTop:'20px'}}>Plan description</span><FormControl 
    onChange={e=>setPlanDescription(e.target.value)}
    />
    <span style={{color:'gray',marginTop:'20px'}}>Plan Category</span><FormControl 
    // placeholder='Course detailed description.'
    onChange={e=>setPlanCategory(e.target.value)}
    />
    
</Row>
    </Col>
    {loading?<Spinner animation="border" role="status">
  <span className="visually-hidden">Loading...</span>
</Spinner>:<Button type='submit'>Create</Button>}
    </Form>
  </Tab>

    </Tabs>
    <div style={{marginBottom:'100px'}} />
        <Tabs
      defaultActiveKey={active==='completed'?'completed':active==='running'?'running':'courses'}
      id="uncontrolled-tab-example"
      className="mb-3"
    >
        <Tab eventKey="renewal-form" title="Renewal Form" onClick={()=>setActive('renewal-form')}>
        <div style={{margin:'40px auto'}}>
                <h3>{`${newState.data.length?newState.data:'Renewal Notice'}`}</h3>
                <Form>
                  <FormControl type='email' placeholder='Email' onChange={a=>setEmail(a.target.value)} />
                  <FormControl type='text' placeholder='Business' onChange={a=>setBusiness(a.target.value)} />
                  <FormControl type='text' placeholder='Doman name' onChange={a=>setDomain(a.target.value)} />
                  {loading?<Spinner/>:<Button onClick={handleRenewal}>Create</Button>}
                </Form>
        </div>
      </Tab>
    </Tabs>
        
    <div style={{marginBottom:'100px'}} />
    <Tabs
      defaultActiveKey={active==='completed'?'completed':active==='running'?'running':'courses'}
      id="uncontrolled-tab-example"
      className="mb-3"
    >
        
      <Tab eventKey="visits" title="Visitors" onClick={()=>setActive('visits')}>
      {visits?.filter(filt=>filt).map((item,index)=>{
                return(
      <ListGroup key={index}>
        <ListGroupItem>{item?.addresses}</ListGroupItem>
        <ListGroupItem>IPv{item?.family}</ListGroupItem>
        <ListGroupItem>{item?.info?.CountryCode}</ListGroupItem>
        <ListGroupItem>{item?.info?.CountryName}</ListGroupItem>
        <ListGroupItem>{item?.info?.StreetAddress}</ListGroupItem>
        <ListGroupItem>{item?.info?.City}</ListGroupItem>
        <ListGroupItem>{item?.info?.RegionName}</ListGroupItem>
        <ListGroupItem>{item?.info?.ZipCode}</ListGroupItem>
        {item.dates.map((itm,ind)=>{
                return(
                        <ListGroup key={ind}>
                                <ListGroupItem>Visits</ListGroupItem>
                                <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Day</span><span style={{color:'gray',marginTop:'20px'}}>{moment(itm?.toString()).format('MMM DD, yyyy')}</span></ListGroupItem>
                        </ListGroup>
                )
        })}
        
      </ListGroup>
                )
            })}
      </Tab>
      
        <Tab eventKey="subscriptions" title="Subscriptions" onClick={()=>setActive('subscriptions')}>
      {subscriptions?.filter(filt=>filt).map((item,index)=>{
                return(
      <ListGroup key={index}>
        <ListGroupItem>{item?.company}</ListGroupItem>
        <ListGroupItem>{item?.participants?.length} Participants</ListGroupItem>
        {item?.subscribed?
        <Button onClick={()=>handleUnSubscriptionConfirmation(item)}>Remove Subscription</Button>:<Button onClick={()=>handleSubscriptionConfirmation(item)}>Confirm Subscription</Button>}
      </ListGroup>
                )
            })}
      </Tab>
      <Tab eventKey="users" title="Users" onClick={()=>setActive('users')}>
      {users?.filter(filt=>filt).map((item,index)=>{
                return(
      <ListGroup key={index}>
        <ListGroupItem>{item?.country.label}</ListGroupItem>
        <ListGroupItem>{item?.firstName}</ListGroupItem>
      </ListGroup>
                )
            })}
      </Tab>

      <Tab eventKey="contacts" title="Contacts" onClick={()=>setActive('contacts')}>
      {contacts?.filter(filt=>filt).map((item,index)=>{
                return(
      <ListGroup key={index}>
        <ListGroupItem>{item?.name}</ListGroupItem>
        <ListGroupItem>{item?.email}</ListGroupItem>
        <ListGroupItem>{item?.phone}</ListGroupItem>
        <ListGroupItem>{item?.message}</ListGroupItem>
        <ListGroupItem>{moment(item?.date?.toString()).format('MMM DD, yyyy')}</ListGroupItem>
      </ListGroup>
                )
            })}
      </Tab>
    </Tabs>
    </Container>;
};

Dashboard.propTypes = {};

export { Dashboard };