import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    const user = useSelector(state=>state.auth.user)
    const {pathname} = useLocation()
    const noFooter = [`/${user?._id}/:amount/:paymentId/:type/withdrawal`]
    
    if(noFooter.some(item=>item===pathname))
    return null
    return (
        <div className='footer-main'>
         <footer className='footer'>
                <div className='footer-bottom'>
<p className='copyright'>copyright &copy; {moment(new Date()).year()} | powered by retiny technology limited</p>
                </div>
            </footer>
        </div>
    );
};

export default Footer;